<template>
  <div class="avicenter">
    <section class="wrapper image-wrapper bg-image bg-overlay bg-overlay-400 text-white px-0 mt-0" :style="{ backgroundImage: 'url(' + require('../../public/assets/i/pageTitle.jpg') + ')' }">
      <div class="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <div class="post-header">
              <h1 class="display-1 mb-3 white">{{ langcontent.raw }}</h1>
              <nav class="d-inline-block" aria-label="breadcrumb">
                <ol class="breadcrumb text-white">
                  <li class="breadcrumb-item"><router-link to="/">{{ langcontent.home }}</router-link></li>
                  <li class="breadcrumb-item active" aria-current="page">{{ langcontent.raw }}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="wrapper bg-light wrapper-border">
      <div class="container pb-14 pb-md-16 pt-5">
        <div class="row pt-5">
          <div class="col-md-3 mb-5" v-for="item in raw.slice().reverse()">
            <div class="card">
              <figure class="card-img-top overlay overlay-1 hover-scale">
                <img :src="item.img" :alt="item.title">
                <span class="bg"></span>
                <figcaption>
                  <h5 class="from-top mb-0">{{item.title}}</h5>
                </figcaption>
              </figure>
              <div class="card-body p-5">
                <div class="post-header">
                  <h2 class="post-title h3 mt-1 mb-3">{{item.title}}</h2>
                </div>
                <div class="post-content">
                  <p v-html="item.desc">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style>
.white{
  color: white!important;
}
.row.gx-0 .col-md-4{
  padding: 0 20px 10px 20px;
}
.display-6{
  padding-left: 20px;
}
.post-content p ul{
  margin: 0;
  padding: 0;
  font-size: 15px;
  list-style: none;
}
</style>

<script>
import iso from "../axios";

export default {
  data() {
    return {
      raw: [],
      langcontent: [],
    }
  },
  created() {
    iso.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
          document.title = 'Bimark | '+this.langcontent.raw;
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('raw')
        .then(response => {
          this.raw = response.data
        })
        .catch(error => {
          console.log(error);
        })
  },
  beforeRouteLeave(to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>